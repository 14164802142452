.TopicButton {
    height: 40px;
    width: 16%;
    margin: 10px 0px;
    border: none;
  }

.TopicButton p {
  margin-top: 10px;
  font-size: 14px;
}