.MiniCalendarView {
    position: relative;
    margin: 0px 1px;
    text-align: center;
    width: 122px;
    float: left;
}

.MiniDateLabel {
    display: block;
    width: 45px;
    height: 45px;
    margin: 10px auto;
    background-color: #fff;
    font-size: 24px;
    text-align: center;
}

.MiniDateLabelTop {
    width: 100%;
    height: 10px;
    background-color: lightseagreen;
    margin-bottom: 4px;
}
