@media only screen{
    .App {
      width: 375px;
      margin: 0 auto;
    }

    .Header {
      width: 100%;
      float: none;
    }

    #LittleLogo {
      width: 170px;
      margin-top: 10px;
      height: 44px;
    }

    #LittleLogoLink {
      position: relative;
      top: 0px;
    }

    #BigLogoLink {
      position: absolute;
      top: -1000px;
    }

    .IntroText {
      background: white;
      padding: 0 20px 5px 20px;
    }

    .AppField {
      text-align: center;
      width: 375px;
      height: 666px;
      margin: 0 auto;
      clear: both;
    }

    .Metrics {
      width: 375px;
      margin: 10px auto;
    }

    .Stats {
      width: 100%;
      color: white;
      font-size: 25px;
      text-align: center;
    }

    p.freeBlocks {
      width: 100%;
      margin: 10px 0px;
    }

    p.prodScore {
      width: 100%;
      margin: 10px 0px;
    }

    .PreviousDays {
      width: 100%;
      margin: 20px auto;
      text-align: center;
    }

    .Charts {
      clear: both;
      width: 100%;
      color: white;
    }

    .Footer {
      clear: both;
      width: 100%;
      color: white;
      text-align: center;
      padding-top: 15px;
    }

    .Footer a {
      color: white;
    }

    .Footer a:visited {
      color: white;
    }

}

@media only screen and (min-width: 800px) and (max-width: 1150px) {
  .App {
    width: 800px;
    margin: 0 auto;
  }

  .Header {
    width: 375px;
    float: right;
    margin-right: 15px;
  }

  #LittleLogo {
    width: 170px;
    margin-top: 10px;
    height: 44px;
  }

  #LittleLogoLink {
    position: relative;
    top: 0px;
  }

  #BigLogoLink {
    position: absolute;
    top: -1000px;
  }

  .IntroText {
    background: white;
    padding: 0 20px 5px 20px;
  }

  .AppField {
    position: sticky;
    top: 10px;
    width: 375px;
    height: 750px;
    margin: 5px 10px;
    float: left;
    clear: none;
  }

  .Metrics {
    width: 375px;
    margin: 0 10px 15px 10px;
    float: left;
    clear: right;
  }

  .Stats {
    width: 100%;
    margin: 10px 0;
    color: white;
    font-size: 20px;
    height: 22px;
  }

  p.freeBlocks {
    text-align: left;
    margin: 10px 0px 20px 0px;
    width: 45%;
    float: left;
  }

  p.prodScore {
    text-align: right;
    margin: 10px 0px 20px 0px;
    width: 45%;
    float: right;
  }

  .PreviousDays {
    width: 100%;
    text-align: center;
    margin: 0px;
    clear: both;
  }

  .Charts {
    clear: both;
    width: 100%;
    color: white;
  }
}


@media only screen and (min-width: 1150px){
  .App {
    width: 1165px;
    margin: 0 auto;
  }

  .Header {
    width: 750px;
    float: right;
    margin-right: 15px;
  }

  #LittleLogoLink {
    position: absolute;
    top: -1000px;
  }

  #BigLogo {
    width: 218px;
    height: 60px;
  }

  #BigLogoLink {
    position: relative;
    top: 0px;
  }

  .IntroText {
    background: white;
    padding: 0 20px 5px 20px;
  }

  .AppField {
    width: 375px;
    margin: 5px 10px;
    float: left;
    clear: none;
  }

  .Metrics {
    width: 750px;
    margin: 0 10px 15px 10px;
    float: left;
  }

  .Stats {
    width: 100%;
    margin: 10px 0;
    color: white;
    font-size: 25px;
    height: 40px;
  }

  p.freeBlocks {
    text-align: left;
    margin: 10px 0px 20px 0px;
    width: 45%;
    float: left;
  }

  p.prodScore {
    text-align: right;
    margin: 10px 0px 20px 0px;
    width: 45%;
    float: right;
  }

  .PreviousDays {
    width: 100%;
    text-align: center;
    margin: 0px;
    clear: both;
  }

  .Charts {
    width: 100%;
    color: white;
  }

  .avgFreqChart {
    width: 50%;
    float: left;
  }

  .todayFreqChart {
    width: 50%;
    float: left;
  }

  .freqChart {
    width: 50%;
    float: left;
  }

  .prodTimeChart {
    width: 50%;
    float: left;
  }
}
