.LoadingSplash {
    position: absolute;
    width: 100%;
    background: black;
    opacity: .75;
    z-index: 1;
    top: 0;
    bottom: 0;
}

.loadingHouse {
    width: 60px;
    height: 60px;
    display: block;
    margin: 0 auto;
}

.loadingSpinner {
    width: 20px;
    height: 20px;
    display: block;
    background: white;
    animation-name: loadIt;
    animation-duration: 3s;
    animation-iteration-count:infinite;
}

@keyframes loadIt {
    from {
        transform: translate3d(0, 0, 0);
    }

    25% {
        transform: translate3d(40px, 0, 0);
    }
  
    50% {
        transform: translate3d(40px, 40px, 0);
    }

    75% {
        transform: translate3d(0, 40px, 0);
    }
  
    to {
        transform: translate3d(0, 0, 0);
    }
  }