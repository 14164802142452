.Calendar {
    position: relative;
    clear: both;
    width: 100%;
    margin: 0 auto;
}

.CalendarRow {
    clear: both;
    width: 360px;
    margin: 0 auto;
}