.DateLabel {
    display: block;
    width: 45px;
    height: 45px;
    margin: 10px auto;
    background-color: #fff;
    font-size: 24px;
}

.DateLabelTopActive {
    width: 100%;
    height: 10px;
    margin-bottom: 4px;
}

.DoubleDateLabel {
    padding-left: 148px;
    width: 212px;
    height: 65px;
    margin: 0 auto;
}

.DateLabelActive {
    display: block;
    width: 45px;
    height: 45px;
    margin: 10px;
    background-color: #fff;
    font-size: 24px;
    float: left;
}

.dot {
    display: block;
    width: 5px;
    height: 5px;
    margin: 35px 11px 0 11px;
    background-color: #555;
    float: left
}

.DateLabelInactive {
    display: block;
    width: 45px;
    height: 45px;
    margin: 10px 0 0 10px;
    background-color: #555;
    font-size: 24px;
    float: left;
}

.DateLabelTopInactive {
    width: 100%;
    height: 10px;
    background-color: #333;
    margin-bottom: 4px;
}
