.TopRow {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.IntroText p {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
  }

.MenuButton {
    display: block;
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    background-color: Transparent;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 10px 0 0;
}

.RefreshButton {
  width: 50px;
  height: 50px;
  border: none;
  background-color: Transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  margin: 4px 0 0 10px;
}

.TextButton {
  background-color: Transparent;
  border: none;
  text-decoration: underline;
  font-size: 16px;
  text-align: left;
  padding: 0 5px 0 0;
  color: blue;
}

.tutorialImage {
  width: 118px;
  height: 100px;
  padding: none;
  margin-bottom: 20px;
  float: left;
}

.tutorialBlock {
  width: 300px;
  margin: 0 auto;
}